import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Ecommerce from './components/ecommerce';
import Enterprise from './components/enterprise';
import Premium from './components/premium';
import Starter from './components/starter';
import Standardhosting from './components/standardhosting';
import Premiumhosting from './components/premiumhosting';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));
ReactDOM.render(<Ecommerce />, document.getElementById('ecommercediv'));
ReactDOM.render(<Enterprise />, document.getElementById('enterprisediv'));
ReactDOM.render(<Premium />, document.getElementById('premiumdiv'));
ReactDOM.render(<Starter />, document.getElementById('starterdiv'));
ReactDOM.render(<Standardhosting />, document.getElementById('standardhostingdiv'));
ReactDOM.render(<Premiumhosting />, document.getElementById('premiumhostingdiv'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
