import React, { Component } from 'react';

class Premium extends Component {
    constructor(props) {
      super(props);
      this.state = {
        error: null,
        isLoaded: false,
        data: []
      };
    }
  
    componentDidMount() {
      fetch('https://www.frequencydesign.co.uk/webservice/public/api/premiumpackages')
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              data: result.data
            });
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    }
  
    render() {
      const { error, isLoaded, data } = this.state;
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div className="divloader"> 

        <div className="loader-container wave-spread">
          <div className="loader">
            <div className="line-1"></div>
            <div className="line-2"></div>
            <div className="line-3"></div>
            <div className="line-4"></div>
            <div className="line-5"></div>
          </div>
        </div>              
        
        </div>;
      } else {
        console.log(this.state.data);
        return (
          <ul className="plan-features">
            {data.map(item => (
              <li  key={item.id}>
                {item.title} 
              </li>
            ))}
          </ul>
        );
      }
    }
  }

  export default Premium;